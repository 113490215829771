import React from 'react';
import { graphql } from 'gatsby';
import Article from 'components/Layouts/Article';

const ArticleTemplate = (props) => <Article {...props} />;

export default ArticleTemplate;

export const articleQuery = graphql`
  query ($handle: String!) {
    contentfulArticlePage(handle: { eq: $handle }) {
      articleTheme {
        title
        blog___page {
          handle
        }
      }
      title
      tags
      id
      contentful_id
      handle
      excerpt
      body {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            id
            gatsbyImageData
            title
            file {
              url
            }
          }
          ... on ContentfulArticleEnhancedContentGridModule {
            id
            items {
              body {
                body
              }
              title
            }
          }
        }
      }
      asset {
        gatsbyImageData(aspectRatio: 1.55, placeholder: NONE)
        title
        file {
          url
        }
      }
      placeholderAsset {
        gatsbyImageData(aspectRatio: 1.55, placeholder: NONE)
        title
        file {
          url
        }
      }
      youtubeVideo
      articleTheme {
        title
      }
      date(formatString: "DD MMMM YYYY")
    }
    allContentfulArticlePage(filter: { handle: { ne: $handle } }, sort: {fields: date, order: DESC}) {
      edges {
        node {
          articleTheme {
            title
            blog___page {
              handle
            }
          }
          title
          tags
          id
          contentful_id
          handle
          body {
            raw
          }
          asset {
            gatsbyImageData(aspectRatio: 1.55, placeholder: NONE)
            title
            file {
              url
            }
          }
          placeholderAsset {
            gatsbyImageData(aspectRatio: 1.55, placeholder: NONE)
            title
          }
          articleTheme {
            title
          }
          date(formatString: "DD MMMM YYYY")
        }
      }
    }
  }
`;

