import styled from 'styled-components';
import Typography from 'components/UI/Typography';

export const StyledArticle = styled.div`
  position: relative;
  color: ${(props) => props.theme.colors.blue};
`;

export const ArticleHead = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    padding: 0 50px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 0;
  }
`;

export const Tags = styled.div`
  margin-bottom: 15px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin-bottom: 20px;
  }
`;

export const TagContainer = styled.div`
  & p {
    font-weight: 700;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    & p {
      font-weight: 500;
      text-transform: uppercase;
    }
  }
`;

export const Tag = styled(Typography)``;

export const ArticleHeadDetails = styled.div`
  display: flex;
  margin-top: 15px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin: 20px 0 60px 0;
  }
`;

export const ReadingTimeStyled = styled.div`
  position: relative;
  padding-left: calc(1em + 2px);

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 2px;
    height: 1.417em;
    background-color: currentColor;
    transform: translateY(-50%);
  }

  .Icon {
    margin-right: 8px;
    margin-bottom: 3px;

    path {
      stroke: ${(props) => props.theme.colors.blue};
    }
  }
`;

export const DateStyled = styled(Typography)`
  padding-right: calc(1em + 2px);
  text-transform: lowercase;
`;

export const Title = styled.h2`
  max-width: 600px;
  font-size: 24px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 36px;
  }
`;

export const RelatedArticlesWrapper = styled.div`
  padding: 30px 0 70px 30px;
  background-color: ${(props) => props.theme.colors.blue};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    padding: 90px 0 70px 60px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 120px 0 70px 60px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    padding: 120px 0 70px 0;
  }
`;

export const MediaAssetContainer = styled.div`
  margin-bottom: 60px;
  width: 100%;
`;

export const TextContentWrapper = styled.div`
  margin: 0 auto;

  ul > li:not(:first-child) {
    margin-top: 10px;
  }

  .BackToTopButton {
    width: 100%;
    margin: 0 auto;
  }
`;

export const SocialLinksMobileWrapper = styled.div`
  position: relative;
  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    display: none;
  }
`;

export const SocialLinksWrapper = styled.div`
  position: relative;
  display: none;
  z-index: 1;
  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    display: block;
    position: absolute;
    top: 440px;
    right: 30px;

    & .SocialLinks {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
    }

    & .SocialLinkContainer {
      margin: 0 auto 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    top: 420px;
    right: 30px;
  }
`;

export const ClipboardText = styled.span`
  position: absolute;
  bottom: 10px;
  left: 30px;
  width: 100px;
  display: block;
  z-index: 1;
  font-size: 13px;
  opacity: 0;
  transition: opacity 300ms;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    bottom: -30px;
    left: -23px;
    font-size: 15px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    bottom: -30px;
    left: -50px;
  }

  &.isVisible {
    opacity: 1;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  margin: 15px 0 30px;
  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin-bottom: 60px;
  }
`;

export const SocialLinkContainer = styled.div`
  margin-right: 20px;
  cursor: pointer;

  & .StyledIcon {
    path {
      fill: ${(props) => props.theme.colors.blue};
    }
  }

  & .IconClipboard {
    path {
      stroke: ${(props) => props.theme.colors.blue};
    }
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const TitleWrapper = styled.div`
  overflow-y: hidden;
`;

export const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  object-fit: cover;
  z-index: 1;
`;