import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';

export const StyledRelatedArticles = styled.div`
  margin: 0 auto;
`;

export const StyledArticlesSliderWrapper = styled.div`
  position: relative;
`;

export const StyledArticlesSliderContainer = styled.div`
  position: relative;

  .Article-Card {
    width: 200px;

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      width: 250px;
    }
  }

  .BottomPanel {
    font-size: 10px;
    & p {
      font-size: 10px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      font-size: 12px;

      & p {
        font-size: 12px;
      }
    }
  }

  .gatsby-image-wrapper {
    img {
      height: 160px;
      width: 200px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      img {
        height: 200px;
        width: 250px;
      }
    }
  }
`;

export const StyledArticlesSlider = styled(Swiper)`
  position: unset;
  width: 100%;
`;

export const RelatedArticlesWrapper = styled.div`
  width: 100%;
  max-width: 1090px;
  margin: 0 auto;
`;

export const RelatedArticlesTitle = styled.h2`
  margin: 0 auto;
  width: 1090px;
  margin-bottom: 60px;
  font-size: 18px;
  color: ${(props) => props.theme.colors.white};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 36px;
  }
`;